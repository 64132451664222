.navbars-wrapper{
    position: sticky;
   top: 0;
    left: 0;
    right: 0;
    z-index: 5;
.navbar {
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 10%;
        background-color: #C47C5A;
        @media screen and (max-width:600px){padding: 20px 20px; height: 10vh;}
        .sign-in-button-nav {
            border: 1px solid #FFFFFF;
            border-radius: 8px;
            padding: 10px 20px;
            background-color: transparent;
            color: white;
            font-weight: bold;
        }

        .form-cart-holder {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 50%;
            @media screen and (max-width:600px){width: auto;position:absolute; right: 125px;}
            form {
                width: 100%;
                margin-right: 20px;
                @media screen and (max-width:600px) {display: none;}
            }

            .search-nav-input {
                background: #FFFFFF;
                mix-blend-mode: normal;
                /* Second/Light grey */
                width: 100%;
                height: 50px;
                padding-left: 40px;
                background-image: url('../../assets/search.svg');
                    background-position: 2% 50%;
                    background-size: 15px;
                    background-repeat: no-repeat;
                    opacity: 1;
                border: 1px solid #F4F5F6;
                border-radius: 8px;
                color: #767676;
                font-weight: 600;
                &:focus{
                    outline: none;
                }

                &::placeholder{
                    font-weight: 600;
                    opacity: 0.6;
                }
                

            }
        }

        .username-nav {
            color: white;
            display: flex;
            align-items: center;
            svg {
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }

        .logo-container {
            img {
                width: 88px;
            }
        }

    }

    .categories-main-navbar {
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5px 5px;
        background-color: #C47C5A;
        
      .scroll-bar{
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 100px;
        height: 5px;
        background-color: white;
      }
        
            /* Track */
          
                       
        @media screen and (max-width:600px) {
                overflow-x: scroll;
                justify-content:flex-start;
            }
        a {
            color: white;
            font-weight: 500;
            margin: 5px 15px;
            text-transform: capitalize;
            white-space: nowrap;
        }
    }
  
}
  .profile-dark-bg {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: hsla(225, 86%, 3%, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 6;

  }
.navbar-search-wrapper {
    width: 100vw;
    background-color: rgba(1, 4, 13, 0.8);
    height: 90vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    position: fixed;
    z-index: 10;
    top: 10vh;
    @media screen and (max-width:600px) {
        top: calc(10vh + 50px);
    }
    left: 0;
    right: 0;

    .search-box {
        width: 45%;
        background-color: white;
        border-radius: 8px;
        padding: 20px 50px;
        margin-top: 50px;
        @media screen and (max-width:600px){
            width: 95%;
        }
        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:last-child {
                border-bottom: 1px solid  transparent;
            }

        }

        h4 {
            color: black;

        }

        img {
            width: 75px;
        }
    }
}
 .form-cart-holder-phone {
     display: flex;
     align-items: center;
     flex-direction: row;
     width: 100%;
    display: none;
    @media screen and (max-width:600px) {
            display: flex;
        }
     form {
         width: 100%;
         margin-right: 20px;
        @media screen and (max-width:600px){margin-right: 0;}
     }

     .search-nav-input {
         background: #FFFFFF;
         mix-blend-mode: normal;
         /* Second/Light grey */
         width: 100%;
         height: 50px;
         padding-left: 40px;
         background-image: url('../../assets/search.svg');
         background-position: 2% 50%;
         background-size: 15px;
         background-repeat: no-repeat;
         opacity: 1;
         border: 1px solid #F4F5F6;
         border-radius: 8px;
        @media screen and (max-width:600px){border-radius: 0px;background-position: 20px 50%;padding-left: 50px;}
         color: #767676;
         font-weight: 600;

         &:focus {
             outline: none;
         }

         &::placeholder {
             font-weight: 600;
             opacity: 0.6;
         }


     }
 }