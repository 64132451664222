.sign-up-offre-wrapper{
    padding: 2% 10%;
    background-color: #F8F2DC;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bolder;
    @media screen and (max-width:600px) {
        flex-direction: column;
        padding: 20px 20px;
    }
    h1{
        margin-top: 0;
    }
    h4{
        font-weight: 400;
    }
    div{
        width: 100%;
        form {
                display: flex;
                flex-direction: row;
                align-items: center;
        
                @media screen and (max-width:600px) {
                    flex-direction: row;
                    width: 100%;
                }
        
                input {
                    mix-blend-mode: normal;
                    border: 1px solid #01040D;
                    border-radius: 8px;
                    margin-right: 10px;
                    background-color: transparent;
                    padding: 10px 20px;
                    width: 250px;
                }
        
                button {
                    padding: 10px 20px;
                    color: white;
                    background: #01040D;
                    border-radius: 8px;
                    border: none;
                }
            }
    }
   
}