.category-screen-wrapper{
    width: 100vw;
    padding: 2% 10%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 50px;
    @media screen and (max-width:600px){
        flex-direction: column;
        padding: 0% 0px;
    }
min-height: 85vh;
        position: relative;

        .phone-brands{
            display: none;
            @media screen and (max-width:600px) {
                    width: 100%;
                        height: 50px;
                        background-color: #C47C5A;
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    padding: 0 20px;
                        h3 {
                            color: white;
                        }
                        span{
                            color: white;
                            transform: rotate(90deg);
                            font-size: 26px;
                        }
                        .brand-phone{
                            position: absolute;
                            width: 100vw;
                            background-color: #C47C5A;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            transform: translateY(100%);
                            z-index: 3;
                            padding: 0px 20px;
                        }
            }
       
        }
        button{
            border-top: 1px solid #F4F5F6;
            border-bottom: 1px solid #F4F5F6;
            border-right: none;
            margin: 5px 0px;
            padding: 10px 0px;
            font-weight: 600;
            border-left: none;
            background-color: transparent;
            color: #C47C5A;
        }
    .filters-box{
        justify-content: space-between;
        width: 20%;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 10px 10px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(27, 25, 86, 0.1);
        border-radius: 8px;
        @media screen and (max-width:600px){display: none;}
        a{
           display: flex;
           align-items: center;
           justify-content: space-between;
        }
        svg{
            width: 10px;
            height: 10px;
        }
        span{
            
        }
        h4{
            margin-top: 10px;
        }
        .filter-buttons{
            display: flex;
            flex-direction: column;
        }
    }
        .product-categories-wrapper{
            width: 100%;
            @media screen and (max-width:600px){padding: 0 20px;}
        }
    .products-box{
        display: flex;
        flex-direction: row;
        grid-column-gap: 15px;
        grid-row-gap: 50px;
        flex-wrap: wrap;
        width: 100%;
        @media screen and (max-width:600px){grid-row-gap: 0px;justify-content:space-between}
    }
}