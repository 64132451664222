.order-tracking-wrapper{
    width: 100vw;
   padding: 2% 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width:600px){
        flex-direction: column;
    }
   .lf{
    width: 50%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width:600px){width: 100%;}
    div{
        background-color:#F4F5F6;
            border-radius: 12px;
            width: 60%;
            padding: 10px;
            color: #C47C5A;
    }
    img {
        width: 60%;
        margin-top: 50px;
    }
   }
   .rl{
    background-color: #C47C5A;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 50%;
    padding: 20px;
    @media screen and (max-width:600px){width: 100%;}
    
    div{
        margin-bottom: 10px;
        margin-top: 10px;
p {
        color: white;
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .track-circle{
        width: 10px;
        height: 10px;
        border: 1px solid white;
        border-radius: 100%;
        margin-right: 10px;
      
        &.filled{
            background-color: white;
        }
    }
    .date-text{
        margin-top: 10px;
        font-weight: 400;
        font-size: 12px;
        margin-left: 20px;
    }
    }
       
   }
}