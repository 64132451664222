.addresses-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    form{
        display: flex;
        flex-direction: column;
        width: 100%;
        input {
            width: 100%;
            margin: 10px 0px;
            padding: 10px 10px;
            border: 1px solid rgba(0, 0, 0, 0.18);
            border-radius: 8px;
        }
        button{
            align-self: center;
        }
    }
    .personal-info-box-holder-form {
        flex-direction: column;
        align-items: center;
        width: 100%;
        display: flex;
    }

    .personal-info-box-holder {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
        width: 100%;
        flex-direction: column;

        .input-holder {

            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0px;
            width: 50%;

            label {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #8991A4;

            }

            input {
                border: 1px solid transparent;
                background-color: transparent;
                text-align: center;
                margin-top: 0px;
                font-style: normal;
                font-weight: 500;
                color: black;
                font-size: 14px;
                font-family: 'Poppins', sans-serif;
                line-height: 22px;

                &:focus {
                    border: 1px solid rgba(0, 0, 0, 0.18);
                    outline: none;
                    background-color: transparent;
                    color: black;
                }
            }
        }

        .value-icon-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0px;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.16);
            padding: 10px 10px;
            border-radius: 8px;
            .value-wrapper {
                
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                span {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                    color: #8991A4;
                    padding-right: 10px;
                    margin-right: 10px;
                    border-right: 1px solid black;

                }

                p {
                    margin: 0px;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 22px;
                }
                
            }
                        .buttons-holder {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            svg {
                                width: 20px;
                                height: 20px;
                                margin: 0 10px;
                                opacity: 0.7;
                            }
                        }

        }
    }

    button {
        background: #01040D;
        border-radius: 8px;
        border: none;
        color: white;
        padding: 15px 70px;
        text-align: center;
        font-weight: 600;
    }
}