.sign-in-wrapper{
    width: 80%;
    @media screen and (max-width:600px) {
        width: 100%;
    }
    .forgot-password-holder {
            text-align: right;
            color: #C47C5A;
            font-size: 14px;
            font-weight: 600;
            &:hover {
                opacity: 0.5;
            }
        }
    form {
        display: flex;
        flex-direction: column;
        align-self: center;
        input {
           
                opacity: 0.8;
                /* Second/Grey */
           width: 100%;
            border: 1px solid #8991a46e;
                border-radius: 8px;
                margin-bottom: 20px;
                height: 35px;
                padding: 0 15px;
                height: 50px;
                height: 50px;
                
        }
        
        button {
            min-width: 60%;
            align-self: center;
            max-width: 100%;
                height: 50px;
                background: #C47C5A;
                border-radius: 8px;
                border: none;
                color: white;
                margin-top: 30px;
                font-weight: 600;
        }
    }
   
}