.download-app-wrapper{
    width: 100%;
    background: linear-gradient(180deg, rgba(250, 251, 251, 0.0001) 0%, #F8FBFF 100%);
    padding: 2% 10%;
    height: 600px;
    @media screen and (max-width:600px){
        height: auto;
        padding: 20px 20px;
    }
    position: relative;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    div{
        width: 40%;
        h1{
            @media screen and (max-width:600px){
                margin-top: 0;
            }
        }
        @media screen and (max-width:600px){
            width: 100%;
        }
        .download-buttons-holder{
            display: flex;
            flex-direction: row;
            margin-top: 50px;
            @media screen and (max-width:600px){
                margin-top: 20px;
            }
            a {
                margin-right: 20px;
            }
        }
    }
    .oval {
        position: absolute;
        right: -5%;
        z-index: 1;
        top: 5%;
        height: 120%;
        @media screen and (max-width:600px) {
                height: 50%;
                right: -90%;
                display: none;
            }
    }
    .waves{
        position: absolute;
        bottom: -10px;
      
        width: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        @media screen and (max-width:600px){
            display: none;
        }
    }
    .phone {
        position: absolute;
        right: 10%;
        top: 15%;
        height: 75%;
        z-index: 2;
        @media screen and (max-width:600px) {
                height: 20%;
                display: none;
            }
    }
}