.personal-info-wrapper{
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   .personal-info-box-holder-form{
    flex-direction: column;align-items: center;
        width: 100%;
        display: flex;
   }
    .personal-info-box-holder{
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
       width: 100%;
    @media screen and (max-width:600px){flex-wrap: wrap; align-items: center; justify-content: center;}
       .input-holder{
        
        display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0px;
            width: 50%;
            
        label{
            display: flex;
            align-items: center;
            font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #8991A4;
           
        }
        input {
            border: 1px solid transparent;
            background-color: transparent;
            text-align: center;
            margin-top: 0px;
            font-style: normal;
            font-weight: 500;
            color: black;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            line-height: 22px;
            @media screen and (max-width:600px) {
                    width: 100%;
                    
                }
            &:focus{
                border: 1px solid rgba(0, 0, 0, 0.18);
                outline: none;
                background-color: transparent;
                color: black;
            }
        }
       }
        
        .value-icon-box {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin: 10px 0px;
                width: 50%;
        
                .value-wrapper {
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                        align-items: flex-start;
                    span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: #8991A4;
        
                    }
        
                    p {
                        margin-top: 0px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
        
            }
    }
   .three-buttons{
    display: flex;
    flex-direction: row;
    @media screen and (max-width:600px){flex-direction: column;}
button ,.button-upd {
        background: #01040D;
        border-radius: 8px;
        border: none;
        color: white;
        height: 50px;
        text-align: center;
        font-weight: 600;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        @media screen and (max-width:600px){
            margin: 5px;
        }

    }
   }
  .button-upd {
      background: #01040D;
      border-radius: 8px;
      border: none;
      color: white;
      height: 50px;
      text-align: center;
      font-weight: 600;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;

      @media screen and (max-width:600px) {
          margin: 5px;
      }

  }
}