.categories-box-holder{
display: -webkit-box;
 
    display: flex;
    width: 100vw;
   padding: 0% 10%;
   flex-direction: column;
@media screen and (min-height:750px) {
        height: 600px;
    }

    @media screen and (min-height:850px) {
        height: 700px;
    }
  
    .categories-map{
        display: flex;
            
          
        
            justify-content: flex-start;
        
            flex-wrap: wrap;
        
            align-items: flex-start;
            grid-column-gap: 15px;
            grid-row-gap: 15px;
        .category-box {
                background-color: #F4F5F6;
                display: flex;
                cursor: pointer;
                flex-direction: row;
                width: 31%;
                max-height: 88px;
                padding: 0px 10px;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;
        
        
        
        
            }
    }
    
}
