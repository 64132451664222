.empty-card-wrapper {
    width: 400px;
    
    background-color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 4;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width:600px){width: 100%;}
    .cart-close-btn-holder{
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 10px;
        left: 0px;
        right: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.074);
        padding: 5px 20px;
        h2 {
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 10px;
            }
            svg {
                width: 15px;
                height: 15px;
                cursor: pointer;
            }

    }
    h2{
        margin-bottom: 10px;
    }
    p {
        margin-top: 0;
color: #8991A4;     
font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    }
    a {
    background: #01040D;
        border-radius: 8px;
        width: 60%;
            height: 64px;
            color: white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
    }
}

.checkout-screen-wrapper {
    width: 400px;
    padding: 2% 0%;
   z-index: 6;
    background-color: white;
    position: fixed;
    right: 0px;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width:600px){width: 100%;}
    .cart-wrapper {
        width: 100%;
        height: 65%;
        .title-button-holder{
            display: flex;
            flex-direction: row;
            align-items: center;
            .cart-close-btn-holder{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.074);
                    padding: 5px 20px;
                h2 {
                        margin-top: 0;
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                button{
                    border: none;
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    font-family: Arial, Helvetica, sans-serif;
                }
                                 svg {
                                     width: 15px;
                                     height: 15px;
                                     cursor: pointer;
                                 }
            }
            justify-content: space-between;
           
            button{
                background-color: transparent;
                border: 1px solid #8991A4;
                    border-radius: 12px;
                    font-size: 12px;
                    padding: 5px 10px;
                    cursor: pointer;
            }
        }
        .cart-items-holder {
            height: 90%;
            overflow-y: scroll;
            padding: 10px 10px;
        }
    }
    .order-summary-wrapper{
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 50%;
        .order-summary-box{
            background-color: #F9FAFB;
            border-radius: 8px;
          padding: 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            .details-info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .details-name{
                    color: #C47C5A;
                    font-size: 12px;
                }
                .details-price{
                    color: black;
                    font-size: 12px;
                }
            }
            .details-info-total{
                    display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        
                        padding-top: 10px;
                        .details-name {
                                color: #C47C5A;
                                font-size: 14px;
                                font-weight: 600;
                            }
                            .details-price-total{
                                font-weight: 700;
                            }
                           
            }
                         .tax {
                             font-size: 12px;
                         }
        .checkout-button{
        
                height: 64px;
                cursor: pointer;
                color: white;
                /* Second/Black */
                border: none;
                font-size: 16px;
                font-weight: 600;
                background: #01040D;
                border-radius: 8px;
        }
        }
    }
}