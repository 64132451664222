.checkouts-wrapper{
    width: 100vw;
    padding: 5% 10%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (min-height:750px) {
            height: 600px;
        }
         @media screen and (max-width:600px){
            flex-direction: column;
            height: auto;
            padding: 20px;
         }
        @media screen and (min-height:850px) {
            height: 600px;
        }
    
        @media screen and (min-height:1000px) {
            height: 900px;
        }
    
        @media screen and (min-height:1200px) {
            height: 1100px;
        }
    .addresses{
        width: 50%;
        padding-right: 50px;
        @media screen and (max-width:600px){
            padding-right: 0px;
            width: 100%;
        }
        h3{
            margin-bottom: 0;
        }
    }
    .guest-box{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        padding: 2% 0%;
        @media screen and (max-width:600px){width:100%}
        form{
            display: flex;
            flex-direction: column;
            width: 100%;
            input {
                font-weight: 100;
                border: 1px solid hsla(222, 13%, 59%, 0.3);
                border-radius: 8px;
                margin-bottom: 10px;
               padding: 15px 0px 15px 15px;
              
                
                    &[type='text'],&[type='email'] {
                        
                        background-position: 5% 50%;
                        background-size: 20px;
                        background-repeat: no-repeat;
                        
                    }
                
                    &[type='number'] {
                       
                        background-position: 5% 50%;
                        background-size: 20px;
                        background-repeat: no-repeat;
                       
                    }
            }
        }
    }
    .order-summary-box{
        background-color: #F9FAFB;
        width: 50%;
        padding: 2% 2%;
        border-radius: 8px;
        flex: 1;
        @media screen and (max-width:600px){width: 100%;}
        h4{margin-top: 0;margin-bottom: 0;}
        .payment-box{
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid black;
            .payment-circle {
                width: 25px;
                height: 25px;
                border: 1px solid black;
                border-radius: 100%;
                margin-right: 10px;
            }
            h2{
                margin-right: 10px;
                
            }
        }

        .total-order-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            &:nth-child(2){
                justify-content: flex-start;
            }
            p {
                color: #C47C5A;
            }
            input{
                border: none;
                padding: 10px;
                width: 30%;
                margin-right: 10px;
                border-radius: 8px;
                @media screen and (max-width:600px) {
                    width: 60%;
                }
            }
            button{
                width: 100px;
                height: 25px;
                background-color: #C47C5A;
                font-size: 14px;
                padding: 15px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover{
                    border: 1px solid #C47C5A;
                    background-color: transparent;
                    color: #C47C5A;
                    opacity: 1;
                }
            }
        }

        button{
            width: 100%;
            border-radius: 8px;
            background-color: black;
            border: 1px solid transparent;
            color: white;
            padding: 20px 0px ;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
.address-info-holder{
    div {
        
          display: flex;
          flex-direction: row;
          align-items: center;
        width: 100%;
        position: relative;
        input[type="radio"] {
               position: absolute;
               left: 10px;
            visibility: hidden;
            @media screen and (max-width:600px){top: 50%; transform: translateY(-55%); right: 15px;}
               &:after{
                
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        top: 0px;
                        left: -1px;
                        position: relative;
                        background-color: transparent;
                        border: 1px solid black;
                        content: '';
                        display: inline-block;
                        visibility: visible;
                       
                    
               }
               &:checked:after{
                background-color: black;
                width: 10px;
                    height: 10px;
                    border-radius: 15px;
                    top: 0px;
                    left: -1px;
                    position: relative;
                   
                    content: '';
                    display: inline-block;
                    visibility: visible;
                    border: 2px solid white;
               }
            }
        
            label {
                border: 1px solid rgba(0, 0, 0, 0.095);
                    border-radius: 8px;
                    padding: 5px 10px;
                   padding-left: 25px;
                    width: 100%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                cursor: pointer;
            }
        
            input:checked+label {
               border: 1px solid black;
            }

        
        
        svg {
            margin-right: 10px;
        }
    }
    button{
        color: black;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        position: absolute;
        right: 5px;
    }
}
.order-received{
    position: fixed;
    z-index: 5;
    width: 100vw;
    top: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
   

    div {
        position: relative;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 60px;
        border-radius: 8px;
        text-align: center;
        h2{
            margin-bottom: 5px;
        }
        
        p {
            color: #8991A4;
            font-weight: 400;
            font-size: 14px;
            margin-top: 10px;
        }
        button {
            background-color: black;
            color: white;
            border: 1px solid transparent;
            padding: 10px 20px;
            border-radius: 8px;
        }
                 .close-order {
                     position: absolute;
                     width: 15px;
                     height: 15px;
                     right: 15px;
                     top: 15px;
                     cursor: pointer;
                     &:hover {
                        opacity: 0.5;
                        
                     }
                 }

    }
}