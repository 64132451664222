.order-auth-wrapper{
    width: 100vw;
    padding: 5% 10%;
    display: flex;
    align-items: center;
    justify-content: center;
   
    @media screen and (min-height:750px) {
            height: 600px;
        }
                 @media screen and (max-width:600px) {
                     padding: 50px 20px;
                     height: auto;
                 }
    
    @media screen and (min-height:850px) {
            height: 600px;
        }
    @media screen and (min-height:1000px) {
        height: 900px;
                }
    @media screen and (min-height:1200px) {
         height: 1100px;
       }
    .auth-navbar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 30px;
    
            a {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #8991A4;
                margin: 0px 10px;
            }
        }
    
        .auth-white-box {
            padding: 2% 2%;
            background-color: white;
            width: 45%;
            border-radius: 8px;
           
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            align-items: center;
            @media screen and (max-width:600px) {
                    width: 95%;
                    padding: 20px;
                }
        }
}