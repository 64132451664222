.checkout-item-container{
    background: #FFFFFF;
        /* Card */
        margin-bottom: 20px;
        box-shadow: 0px 4px 30px rgba(27, 25, 86, 0.1);
        border-radius: 8px;
        display: flex;
        height: 80px;
        align-items: center;
        width: 100%;
        padding: 10px 10px;
        position: relative;
        .image-container{
          img{
            object-fit: contain;
max-width: 50px;
max-height: 50px;
          } 
        }
        .price {
                position: absolute;
                right: 20px;
                top: 10px;
                font-size: 12px;
                font-weight: 700;
            }
            .remove-button{
                position: absolute;
                right: 20px;
                bottom: 10px;
                svg{
                    width: 20px;
                    height: 20px;
                }
               cursor: pointer;
                
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: red;
                font-weight: 600;
                opacity: 1;
                &:hover{
                    opacity: 0.8;
                }
            }
        .info-container{
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .name {
                    margin-bottom: 5px;
                    color: #C47C5A;
                    font-size: 14px;
                    font-weight: 600;
                }
                .add-info-holder {
                    display: flex;
                    align-items: row;
                        .minus,
                            .plus {
                                border: 1px solid black;
                                border-radius: 100%;
                                width: 25px;
                                height: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                &:hover{
                                    opacity: 0.5;
                                }
                                &:active{
                                    background-color: black;
                                    color: white;
                                }
                            }
                            .quantity{
                                margin: 0 10px;
                            }
                }
                
        
        }
        
        img {
            width: 75px;
        }
}