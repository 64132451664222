.promoted-items-wrapper {
    display: -webkit-box;
   
    display: flex;
    width: 100%;
   
    flex-direction: column;


    .promoted-items-map {
        display: flex;
@media screen and (max-width:600px) {
        grid-column-gap: 0px;
            justify-content: space-between;
    }
padding: 0% 0% 0% 0%;

        justify-content: flex-start;

        flex-wrap: wrap;

        align-items: flex-start;
        grid-column-gap: 50px;
        grid-row-gap: 15px;

        .category-box {
            background-color: #F4F5F6;
            display: flex;
            flex-direction: row;
            width: 31%;
            max-height: 88px;
            padding: 0px 10px;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;




        }
    }

}