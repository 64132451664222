.auth-wrapper{
    width: 100vw;
    height: 100vh;
    background-color: #C47C5A;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 50px;
    @media screen and (max-width:600px){padding-top: 20px;}
    .auth-navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0px 0px;
        margin-bottom: 50px;
        a {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #8991A4;
            margin: 0px 10px;
        }
    }
    .auth-white-box{
        padding: 2% 10%;
        background-color: white;
        width: 45%;
        border-radius: 8px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width:600px){width: 95%;padding:10%}
       
    }
}