.orders-wrapper{
    padding: 5% 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
    h1{
        text-align: left;
    }
    .order-box-holder{
        background: #FFFFFF;
        box-shadow: 0px 3.28796px 24.6597px rgba(27, 25, 86, 0.1);
        border-radius: 13.1518px;
        padding: 10px 20px;
        width: 40%;
        margin: 20px 0;
        @media screen and (max-width:600px){width: 100%;}
        .order-cart-item{
            display: flex;
            flex-direction: row;
            align-items: center;
          margin-bottom: 20px;
            img{
                width: 100px;
                margin-right: 15px;
            }
        }
        .cart-orders-info{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            p{
                color: #C47C5A;
            }
        }
        .details-button{
            background-color: transparent;
            color: #C47C5A ;
            border-top: 0.82199px solid #F4F5F6;
            border-bottom: 0.82199px solid #F4F5F6;
            width: 100%;
            border-left: none;
            border-right: none;
            border-radius: 0;
        }

        button{
            width: 100%;
            background-color: black;
            border-radius: 8px;
            color: white;
            padding: 10px 20px;
            border: none;
        }
    }
}