.promoted-sec{
    padding: 5% 10%;
    @media screen and (max-width:600px) {
        padding: 2% 20px;
    }
}
.swiper {
    width: 100%;
    height:600px;
    @media screen and (max-width:600px) {
            height: 200px;
        }
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
    .swiper-wrapper{
        height: 100%;
      
    }
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
height: 100%;
width: 100%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
:root {
    --swiper-theme-color: white !important;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-button-next{
    right: 30px !important;
}
.swiper-button-prev {
    left: 30px !important;
}
.swiper-pagination-bullet-active{
    background-color: #C47C5A !important;
}
.swiper-button-prev:after,.swiper-button-next:after{
    
    font-size: 30px !important;
    color: #C47C5A!important;
}