.sign-up-wrapper {

    width: 80%;
    @media screen and (max-width:600px) {
        width: 100%;
    }
display: flex;
align-items: center;
    form {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        input {
         
            opacity: 0.8;
            /* Second/Grey */
          width: 100%;
            border: 1px solid #8991a46e;
            border-radius: 8px;
            margin-bottom: 20px;
            height: 35px;
            padding: 0 15px;
            height: 50px;
            height: 50px;

         
                    
        }

        button {
            
            min-width: 60%;
            height: 50px;
            background: #C47C5A;
            border-radius: 8px;
            border: none;
            color: white;
            margin-top: 50px;
            font-weight: 600;
            @media screen and (max-width:600px){
               margin-top: 10px;
            }
        }
    }

}