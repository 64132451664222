

.solo-product-page-wrapper{
width: 100vw;
background-color: #F4F5F6;

    .solo-product-wrapper {
            width: 100vw;
            padding: 5% 10%;
            background-color: white;
        display: flex;
        flex-direction: row;
        @media screen and (max-width:600px){flex-direction: column;}
            .product-image-bg{
                width: 50%;
                @media screen and (max-width:600px){width: 100%;height:200px}
                display: flex;
                align-items: center;
                justify-content: center;
                    background-position: 50% 50%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-attachment: scroll;
                 
            }
            .product-details-box{
                width: 50%;
                @media screen and (max-width:600px){width: 100%;}
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                h1{
                    margin-bottom: 10px;
                }
                h4{
                    margin-top: 0;
                    color: #C47C5A;
                    margin-bottom: 20px;
                }
                h2{
                    color: #C47C5A;
                    margin-top: 0;
                }
                button {
                    background-color: #C47C5A;
                    color: white;
                    border: 1px solid transparent;
                    padding: 20px 50px;
                    font-weight: 600;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 16px;
                }
            }
        }
        .prom-items{
            padding: 2% 10%;
            @media screen and (max-width:600px) {
                padding: 20px;
            }
        }
}
