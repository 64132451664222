.footer-wrapper {
    background-color: #C47C5A;
    width: 100%;
    padding: 2% 10%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width:600px) {
        flex-direction: column;
        padding: 20px;
    }
    .social-media-holder{
        padding-left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        a{
            margin-right: 15px;
        }
    }
    div {
        h2{
            color: white;
            font-weight: bolder;
        }
        padding: 0% 20px;
        display: flex;
        flex-direction: column;
        width: 20%;
                 @media screen and (max-width:600px) {
                     width: 100%;
                    padding: 0% 0px;
                 }
        h4{
            color: white;
            font-weight: bold;
            font-weight: 900;
            margin-top: 0;
        }
        a{
            color: white;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }.l2{
        width: 30%;
        @media screen and (max-width:600px) {
                width: 100%;
            }
    }
}