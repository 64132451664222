.profile-wrapper {
    min-width: 800px;
    width: 50%;
    max-width: 800px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  position: relative;
    padding: 25px;
    @media screen and (max-width:600px){width: 90%; min-width:0;}
    .close-profile-btn{
        border: none;
        background-color: transparent;
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        font-size: 22px;
        color: #C47C5A;
        cursor: pointer;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
    }
    .clr-bckg{

        padding: 30px 5px;
        width: 100%;
        background-color: #C47C5A;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        h2{
            color: white;
        }
    }

    

}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    margin: 30px 0px;
}

.react-tabs__tab-list {
   
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    button{
        border: 1px solid black;
            border-radius: 8px;
            background-color: transparent;
            font-size: 14px;
            color: black;
            padding: 5px 10px;
            margin: 0 10px;
            list-style-type: none;
            cursor: pointer;
    }
}

.react-tabs__tab {
    border: 1px solid black;
        border-radius: 8px;
        background-color: transparent;
        font-size: 14px;
        color: black;
        padding: 5px 10px;
        margin: 0 10px;
        list-style-type: none;
    cursor: pointer;
}

.react-tabs__tab--selected {
    
    background-color: black;
    color: white;
    
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}