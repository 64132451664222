.product-card-container {
    display: flex;
    align-items: center;
   flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
    box-shadow: 0px 4px 30px rgba(27, 25, 86, 0.1);
    border-radius: 8px;
    width: calc(100% / 5);
    @media screen and (max-width:600px) {
            width: 46%;
            height: 300px;
            margin-bottom: 30px;
        }
    height: 350px;
    background-color: white;
    cursor: pointer;
    .product-card-img{
        padding: 5% 5% 30px 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .name {
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                color: #C47C5A;
                margin-bottom: 10px;
                text-align: center;
            }
        
            .price {
                font-weight: 900;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #01040D;
                margin-bottom: 0px;
            }
    }
 
    img {
       
        object-fit: cover;
       max-width: 100%;
        margin-bottom: 20px;
        max-height: 150px;
        @media screen and (max-width:600px) {
           
                max-height: 150px;
            }
    }
   .add-product{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: #C47C5A;
        border:none;
        color: white;
        font-size: 25px;
        
        transform: translate(-50%,50%);

        box-shadow: 0px 8px 20px rgba(109, 108, 108, 0.3);
        border-radius: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 3;
        left: 50%;
        bottom: 0;
        cursor: pointer;
    }
    .heart-svg{
        position: absolute;
        top: 10px;
        right: 10px;
    }
}