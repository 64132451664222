.add-address-order-wrapper{
    width: 100vw;
    padding: 3% 10%;
    @media screen and (min-height:750px) {
            height: 600px;
        }
    @media screen and (max-width:600px) {
        height: auto;
        padding: 20px 20px;

    }
        @media screen and (min-height:850px) {
            height: 600px;
        }
    
        @media screen and (min-height:1000px) {
            height: 900px;
        }
    
        @media screen and (min-height:1200px) {
            height: 1100px;
        }
    form {
            display: flex;
            flex-direction: column;
            width: 50%;
    @media screen and (max-width:600px){width: 100%;}
            input {
                font-weight: 500;
                border: 1px solid hsla(222, 13%, 59%, 0.3);
                border-radius: 8px;
                margin-bottom: 10px;
                padding: 15px 0px 15px 15px;
                
    
              
            }
            button {
                background-color: #C47C5A;
                border-radius: 8px;
                border: 1px solid transparent;
                color: white;
                padding: 5px 0px;
                font-weight: 600;
                width: 50%;
                margin-top: 20px;
            }
        }
}