body{
    box-sizing: border-box;
}
a:hover,button:hover{
    opacity: 0.6;
    transition: all 200ms ease-in-out;
}
button{
    cursor: pointer;
}
.age-wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    .age-box{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C47C5A;
        width: 400px;
        padding: 30px;
        color: white;
        text-align: center;
        border-radius: 8px;
        p{
            font-size: 12px;
        }
        img{
            width: 40%;
        }
        button{
            border: 1px solid transparent;
            background-color: white;
            padding: 5px 10px;
            cursor: pointer !important;
            border-radius: 5px;
            &:hover{
                background-color: transparent;
                color: white;
                border: 1px solid white;
                opacity: 1;
            }
        }
    }
}