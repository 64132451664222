.order-details-wrapper{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
 h2{
    margin-top: 10px;
    margin-bottom: 15px;
 }
    span {
        background-color: #C47C5A;
        padding: 5px 10px;
        color: white;
        border-radius: 8px;
        font-size: 12px;
    }
    .details-holder{
        width: 55%;
        @media screen and (max-width:600px){width: 90%;}
        background: #FFFFFF;
            /* Card */
        padding: 10px;
            box-shadow: 0px 4px 30px rgba(27, 25, 86, 0.1);
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            h5{
                margin-bottom: 0;
            }
            h3{
                margin-top: 0;
            }
    }

    .order-item-holder{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .name-holder{
        display: flex;
        align-items: center;
        flex-direction: row;
        img{
            margin-right: 10px;
            max-width: 80px;
            max-height: 80px;
            object-fit: contain;
        }
       p{
            color: #C47C5A;
        }
        .quant{
            margin-left: 10px;
        }
        
      
      }
          .price {
              color: #C47C5A;
          }
    }
    .track-button{
        background-color: black;
        border: 1px solid transparent;
        color: white;
        align-self: center;
        width: 50%;
        height: 40px;
        border-radius: 8px;
    }
}